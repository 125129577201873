@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.fadeprotfolio-in {
  animation: fadeIn 0.8s forwards;
}

.animatefaq-fadeIn {
  animation: fadeIn 0.5s ease forwards;
}

@keyframes slideDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animatefaq-slideDown {
  animation: slideDown 0.5s ease forwards;
}

.custom-toast {
  background-color: #113270 !important; /* Green background for success */
  color: white !important; /* White text color */
  border-radius: 8px !important; /* Optional: Rounded corners */
  padding: 10px 20px !important; /* Optional: Add padding */
  font-size: 16px !important; /* Optional: Adjust font size */
}

.custom-toast .Toastify__icon--success {
  color: #ffd700 !important; /* Gold color for the success icon */
}

@keyframes imageHover {
  0% {
    transform: scale(1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
}

.img-hover-effect:hover {
  animation: imageHover 0.3s ease-in-out forwards;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #000 #0000;
  animation: l1 1s infinite;
}
@keyframes l1 {
  to {
    transform: rotate(0.5turn);
  }
}
